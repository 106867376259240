.menu_overlay {
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1;
}

.menu_open {
    color: $text-siyah;
    &:hover {
        color: $kirmizi;
    }
}
.mobile-menu {
    margin-top:20px;
    overflow-x: hidden;
}

.languages {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
    a {
        color: $text-siyah;
    }
}

.slide-menu {
    padding:0 20px;
    width: 200px;
    background-color: #FFF;
    box-shadow: 3px 4px 6px -3px #DDD, -3px 4px 6px -3px #DDD;

    a {
        &.links {
            color: $text-siyah;
            display: block;
            padding: 10px 0;
            border-bottom: 1px solid #EFEFEF;

            &:hover {
                color: $kirmizi;
            }
        }
    }

    .search-holder {
        position: relative;
        right: 0;
        top: 15px;
        z-index: 10;
        text-align:center;
        margin-bottom: 30px;
        display:flex;

        input {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
            border: none;
            border-radius: 5px;
            padding: 0 15px;
            height:30px;
            width:calc(100% - 50px);
            &::placeholder {
                color: #CECECE;
            }
        }

        button {
            position: relative;
            border-radius: 5px;
            border: none;
            padding:0.375rem 0;
            min-width:50px;
            width: 70px;
            height: 30px;
            font-size:12px;
        }
    }

    .social-media-mobile {
        position: relative;
        color: #A1A1A1;
        a {
            color: $text-siyah;
        }

        div {
            font-size: 0.875rem;

            &:first-child {
                position: relative;
                font-size: 0.7rem;
                display: inline-block;

                &:after {
                    font-family: "Font Awesome 5 Free";
                    content: '\f111';
                    color: $kirmizi;
                    font-weight: 900;
                    position: absolute;
                    right: -14px;
                    top: 2px;
                    font-size: .5rem;
                }
            }
        }
    }
    .controls {
        display: flex;
        margin-bottom: 1rem;
    }

    .slide-menu__backlink {
        color: $text-siyah;
        text-transform: uppercase;
    }
}
