// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~@grubersjoe/slide-menu/dist/slide-menu.css';
@import './slide_menu';
@import './custom';

